<template>
  <div class="home">
    <section class="homeTop">
      <div class="container">
        <p class="note">1st MUSEES Conference</p>
        <h1>
          Modeling Uncertainty in Social, Economic, and Environmental Sciences
        </h1>
        <p class="date">March 16-18, 2022, Lyon, France</p>
        <span class="line line-0"></span>
        <span class="line line-1"></span>
        <span class="line line-2"></span>
        <span class="line line-3"></span>
        <span class="line line-4"></span>
        <span class="line line-5"></span>
      </div>
    </section>
    <section class="container homeMain">
      <div class="left">
        <div id="speakers">
          <i class="square"></i>
          <h2>Keynote Speakers</h2>

          <h3>
            <a href="http://rama.cont.perso.math.cnrs.fr/" target="_blank"
              >Rama Cont</a
            >
          </h3>

          <p>Professor, Mathematical Institute, University of Oxford</p>

          <h3>
            <a
              href="https://www.tse-fr.eu/fr/people/christian-gollier"
              target="_blank"
              >Christian Gollier</a
            >
          </h3>

          <p>
            Professor, Toulouse School of Economics
            <span class="title">Pricing carbon under uncertainty</span>
          </p>

          <h3>
            <a
              href="https://thema.u-cergy.fr/membres/enseignants-chercheurs/de-palma"
              target="_blank"
              >André de Palma</a
            >
          </h3>

          <p>
            Professor at CY Cergy Paris Université, THEMA
            <span class="title">Pricing vehicle emissions and congestion</span>
          </p>
        </div>
        <div class="content">
          <p>
            <strong>1st Conference</strong> of the <strong>M</strong>odeling
            <strong>U</strong>ncertainty in <strong>S</strong>ocial,
            <strong>E</strong>conomic, and <strong>E</strong>nvironmental
            <strong>S</strong>ciences <strong>(MUSEES)</strong> Association will
            be held at
            <strong>emlyon Business School (FRANCE), March 16-18, 2022</strong>.
          </p>
          <p>
            <strong>PAPER DETAILS:</strong> Papers on any topic related to
            <strong class="large">modeling in social sciences</strong> (such as
            economics, finance, risk management, insurance, health, sociology,
            psychology, or environmental sciences) will be considered. Papers
            should be written and presented in English. If your paper is
            accepted, you are expected to act as a chair and/or as a discussant
            in your field of research.
          </p>
          <p>
            An award will be presented to the author(s) of the conference’s best
            paper.
          </p>
          <div class="box">
            <p>
              Submission and contact via the following email address:
              <a href="mailto:musees2022lyon@em-lyon.com"
                >musees2022lyon@em-lyon.com</a
              >
            </p>
            <p>
              <strong
                >The deadline for the submission of a long abstract or paper is
                September 30, 2021.</strong
              >
            </p>
            <p>
              The notification of the program committee decisions will be sent
              to authors by November 30, 2021.
              <br />The registration deadline will be January 31, 2021.
            </p>
          </div>
          <p class="italic">If possible, travel by train, not by plane!</p>

          <p>We look forward to seeing you in Lyon,</p>
          <p>
            Yann Braouezec, Ieseg School of Management<br />
            André Gorius, Govalia<br />
            Olivier Le Courtois, emlyon business school<br />
            Sébastien Lleo, NEOMA Business School<br />
            Jean-Luc Prigent, CY Cergy Paris University
          </p>

          <a
            class="button"
            href="https://website-53096.eventmaker.io/"
            target="_blank"
            >GO TO REGISTRATION WEBSITE</a
          >
          <a class="button pdf" :href="pdfLink" target="_blank"
            ><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
              <path
                d="M181.9 256.1c-5-16-4.9-46.9-2-46.9 8.4 0 7.6 36.9 2 46.9zm-1.7 47.2c-7.7 20.2-17.3 43.3-28.4 62.7 18.3-7 39-17.2 62.9-21.9-12.7-9.6-24.9-23.4-34.5-40.8zM86.1 428.1c0 .8 13.2-5.4 34.9-40.2-6.7 6.3-29.1 24.5-34.9 40.2zM248 160h136v328c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V24C0 10.7 10.7 0 24 0h200v136c0 13.2 10.8 24 24 24zm-8 171.8c-20-12.2-33.3-29-42.7-53.8 4.5-18.5 11.6-46.6 6.2-64.2-4.7-29.4-42.4-26.5-47.8-6.8-5 18.3-.4 44.1 8.1 77-11.6 27.6-28.7 64.6-40.8 85.8-.1 0-.1.1-.2.1-27.1 13.9-73.6 44.5-54.5 68 5.6 6.9 16 10 21.5 10 17.9 0 35.7-18 61.1-61.8 25.8-8.5 54.1-19.1 79-23.2 21.7 11.8 47.1 19.5 64 19.5 29.2 0 31.2-32 19.7-43.4-13.9-13.6-54.3-9.7-73.6-7.2zM377 105L279 7c-4.5-4.5-10.6-7-17-7h-6v128h128v-6.1c0-6.3-2.5-12.4-7-16.9zm-74.1 255.3c4.1-2.7-2.5-11.9-42.8-9 37.1 15.8 42.8 9 42.8 9z"
              />
            </svg>
            <span>Download Call for Papers</span></a
          >
        </div>
        <div class="fees">
          <i class="square"></i>
          <h4>Subscription fees</h4>
          <p>
            350 € for Academics<br />
            150 € for PhD students<br />
            600 € for Practitioners
          </p>
        </div>
      </div>
      <div class="right">
        <h4>MUSEES Board Members</h4>
        <ul>
          <li></li>
          <li>Yann Braouezec, Iéseg School of Management</li>
          <li>André Gorius, Govalia</li>
          <li>Olivier Le Courtois, emlyon business school</li>
          <li>Sébastien Lleo, NEOMA Business School</li>
          <li>Jean-Luc Prigent, CY Cergy Paris University</li>
        </ul>
        <h4>2022 Lyon Conference Local Organization Committee</h4>
        <ul>
          <li>Eric André</li>
          <li>Benoit Chevalier-Roignant</li>
          <li>Guillaume Coqueret</li>
          <li>Olivier Le Courtois</li>
          <li>Bertrand Maillet</li>
          <li>Yacine Rekik</li>
          <li>Bertrand Tavin</li>
          <li>Bruno Versaevel</li>
          <li>Owen Williams</li>
        </ul>
        <h4>Scientific Committee</h4>
        <ul>
          <li>Carole Bernard, GEM</li>
          <li>Philippe Bertrand, IAE Aix-Marseille &amp; Kedge</li>
          <li>Eric Brian, EHESS</li>
          <li>Catherine Bruneau, Université Paris 1</li>
          <li>David Crainich, Iéseg</li>
          <li>Jean-Paul Décamps, Université de Toulouse</li>
          <li>Vianney Dequiedt, Université Clermont Auvergne</li>
          <li>Pierre Devolder, UCLouvain</li>
          <li>Johanna Etner, Nanterre</li>
          <li>Hélyette Geman</li>
          <li>Ani Guerdjikova, Grenoble</li>
          <li>Montserrat Guillen, Barcelona</li>
          <li>Monique Jeanblanc, Université d’Evry</li>
          <li>Elyes Jouini, Dauphine</li>
          <li>Donatien Hainaut, UCLouvain</li>
          <li>Nabil Kahale, ESCP</li>
          <li>Marie Kratz, ESSEC</li>
          <li>Jean-Paul Laurent, Université Paris 1</li>
          <li>Delphine Lautier, Université Paris-Dauphine</li>
          <li>François Longin, Essec</li>
          <li>Henri Loubergé, Genève</li>
          <li>Jacques-Lévy Véhel, Case Law Analytics</li>
          <li>Stéphane Loisel, ISFA</li>
          <li>Philippe Madiès, Grenoble</li>
          <li>Yannick Malevergne, Université Paris 1</li>
          <li>Véronique Maume-Deschamps, Université Lyon 1</li>
          <li>Francesco Menoncin, Université de Brescia</li>
          <li>Franck Moraux, Université de Rennes</li>
          <li>Caroline Orset, AgroParisTech</li>
          <li>Pierre Picard, ENSAE-X</li>
          <li>Rivo Randrianarivony, Université de Rouen</li>
          <li>Béatrice Rey-Fournier, Université Lyon 2</li>
          <li>Christian-Yann Robert, ENSAE</li>
          <li>Patrick Roger, Université de Strasbourg</li>
          <li>Frédéric Vrins, UCLouvain</li>
          <li>Christian Walter, consultant</li>
          <li>Georges Zaccour, HEC Montreal</li>
        </ul>
      </div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from "@/components/HelloWorld.vue";

export default {
  name: "Home",
  components: {},
  data() {
    return {
      pdfLink: "./Call For Papers MUSEES 2022.pdf",
    };
  },
};
</script>
<style lang="scss" scoped>
.homeTop {
  color: white;
  font-family: Montserrat;
  text-align: left;
  background: linear-gradient(275.21deg, #790758 17.37%, #140544 95.48%);
  .container {
    position: relative;
    padding: 5px 20px 8px 20px;
    margin: auto;
  }
  .note {
    font-size: 15px;
    font-style: italic;
    font-weight: 600;
    opacity: 0.7;
  }
  h1 {
    font-size: 24px;
    font-weight: 400;
    text-transform: uppercase;
  }
  .date {
    font-size: 18px;
  }
  .emlyon {
    position: absolute;
    top: 0;
    right: 0;
    width: 50px;
    height: 50px;
    img {
      max-width: 100%;
    }
  }
  .line {
    position: absolute;
    display: block;
    width: 50px;
    height: 50px;
    border: 1px solid rgba(white, 0.5);
  }
  .line-0 {
    top: 0;
    right: 0;
  }
  .line-1 {
    top: 50px;
    right: 0;
    border-top: 0;
  }
  .line-2 {
    border-top: 0;
    border-bottom: 0;
    top: 100px;
    right: 0;
  }
  .line-3 {
    top: 150px;
    right: 0;
  }
  .line-4 {
    top: 0;
    right: 50px;
    border-right: 0;
  }
  .line-5 {
    border-top: 0;
    border-right: 0;
    top: 50px;
    right: 50px;
  }
}
.homeMain {
  margin: 30px auto;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  text-align: left;
  font-size: 16px;
  font-family: "Roboto";
  h2 {
    font-family: Montserrat;
    font-size: 26px;
    font-weight: 400;
    margin-top: 0;
    line-height: 1.2;
    padding-left: 40px;
  }
  h3 {
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    margin-top: 1.6rem;
    margin-bottom: 0;
  }
  h4 {
    margin-top: 0;
    margin-bottom: 10px;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0 0 0 15px;
  }
  .left {
    flex: 2.5 0 0;
    line-height: 1.4;
    .content p {
      margin-bottom: 25px;
    }
  }
  .right {
    flex: 1 0 0;
    border-top: 5px solid $mallow;
    line-height: 1.4;
    margin-top: 15px;
    padding-top: 30px;
    ul {
      margin-bottom: 30px;
      font-size: 16px;
    }
  }
  .box {
    background: $mallow;
    color: white;
    padding: 5px 20px;
    margin: 20px 0 30px 0;
    a {
      color: white;
      text-decoration: underline;
      &:hover {
        text-decoration: none;
        opacity: 0.7;
      }
    }
  }
  .button {
    border-radius: 5px;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 1;
    padding: 5px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 200px;
    max-width: 310px;
    height: 40px;
    background: $orange;
    color: white;
    margin: 10px 0;
    transition: all 0.3s;
    cursor: pointer;
    &:hover {
      background: #c30051;
    }
    &.pdf {
      background: white;
      color: $purple;
      border: 1px solid $purple;
      padding-left: 15px;
      svg {
        height: 100%;
        margin-right: 15px;
        fill: $purple;
        transition: all 0.3s;
      }
      &:hover {
        color: $mallow;
        border-color: $mallow;
        background-color: #f5eef3;
        svg {
          fill: $mallow;
        }
      }
    }
    + .button {
      margin-top: 20px;
    }
  }
}
.square {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  background: $orange;
}
#speakers {
  position: relative;
  border-bottom: 1px solid $orange;
  padding-bottom: 25px;
  margin-bottom: 30px;
  .square {
    width: 30px;
    height: 30px;
  }
  .title {
    display: block;
    font-style: italic;
  }
  p {
    margin: 10px 0;
    line-height: 1.28;
  }
}
.fees {
  position: relative;
  padding-left: 80px;
  padding-top: 20px;
  margin-top: 30px;
  border-top: 1px solid $orange;
  .square {
    top: 20px;
    width: 45px;
    height: 45px;
  }
}
@media (min-width: 500px) {
  .homeTop {
    .container {
      padding: 10px 20px 13px 20px;
    }
    .note {
      font-size: 20px;
    }
    h1 {
      font-size: 30px;
    }
    .date {
      font-size: 22px;
    }
  }
  .homeMain {
    h2 {
      font-size: 30px;
      line-height: 1.4;
      padding-left: 0;
    }
    h3 {
      margin-top: 1.8rem;
    }
    .box {
      padding: 10px 30px;
      margin: 30px 0 40px 0;
    }
    .button {
      margin: 40px 0;
      + .button {
        margin-top: 20px;
        margin-bottom: 60px;
      }
    }
    .right {
      margin-top: 30px;
    }
  }
  #speakers {
    padding-left: 80px;
    margin-bottom: 40px;
    .square {
      width: 45px;
      height: 45px;
    }
    p {
      margin: 1em 0;
      line-height: 1.4;
    }
  }
}
@media (min-width: 700px) {
  .homeTop {
    .emlyon {
      width: 80px;
      height: 80px;
    }
    .line {
      width: 80px;
      height: 80px;
    }
    .line-1 {
      top: 80px;
    }
    .line-2 {
      top: 160px;
    }
    .line-3 {
      top: 240px;
    }
    .line-4 {
      right: 80px;
    }
    .line-5 {
      top: 80px;
      right: 80px;
    }
  }
}
@media (min-width: 920px) {
  .homeMain {
    flex-direction: row;
    font-size: 18px;
    .left {
      padding-right: 30px;
    }
    .right {
      border-top: 0;
      margin-top: 0;
      padding-top: 0;
      padding-left: 20px;
      border-left: 1px solid $mallow;
      ul {
        margin-bottom: 40px;
      }
    }
  }
}
@media (min-width: 1024px) {
  .homeTop {
    //height: 385px;
    .container {
      padding: 18px 20px 20px 60px;
    }
    .note {
      font-size: 30px;
    }
    h1 {
      max-width: 1170px;
      font-size: 48px;
      padding-right: 110px;
    }
    .date {
      font-size: 36px;
    }
    .emlyon {
      width: 106px;
      height: 106px;
    }
    .line {
      width: 106px;
      height: 106px;
    }
    .line-1 {
      top: 106px;
    }
    .line-2 {
      top: 212px;
    }
    .line-3 {
      top: 318px;
    }
    .line-4 {
      top: 0;
      right: 106px;
    }
    .line-5 {
      top: 106px;
      right: 106px;
    }
  }
  .homeMain {
    margin: 60px auto;
    flex-direction: row;
    padding: 0 30px 0 60px;
    font-size: 18px;
    h2 {
      font-size: 48px;
    }
    h3 {
      font-size: 24px;
      margin-top: 3rem;
      margin-bottom: 1rem;
    }
    h4 {
      margin-top: 10px;
      margin-bottom: 1.33em;
    }
    .left {
      padding-right: 60px;
    }
    .right {
      border-top: 0;
      margin-top: 0;
      padding-top: 0;
      padding-left: 40px;
      border-left: 1px solid $mallow;
      ul {
        margin-bottom: 40px;
      }
    }
    .box {
      padding: 15px 40px;
      margin: 30px 0 40px 0;
    }
  }
  #speakers {
    padding-left: 120px;
    padding-bottom: 50px;
    margin-bottom: 50px;
    .square {
      width: 65px;
      height: 65px;
    }
  }
}
</style>