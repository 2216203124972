<template>
  <div id="app">
    <div id="nav">
      <router-link to="/" id="logo"
        ><img alt="MUSEES" src="@/assets/img/logo.png"
      /></router-link>
      <span id="baseline"
        >Modeling Uncertainty in Social, Economic, and Environmental
        Sciences</span
      >
    </div>
    <router-view />
    <footer>
      <router-link to="/legal-mentions">Legal mentions</router-link>
      <span>Copyright {{ currentDateTime() }} &nbsp;&copy;MUSEES</span>
    </footer>
  </div>
</template>

<script>
export default {
  methods: {
    currentDateTime() {
      const current = new Date();
      const date = current.getFullYear();
      return date;
    },
  },
};
</script>
